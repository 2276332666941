/*---------------------------------
    kabosu
---------------------------------*/
.container{
    &__thumb{
        margin-bottom: 60px;
        &Img{}
    }
}

.hierarchy__title{
    &Br{
        padding-left: .25em;
        @include mq() {
            padding-left: 0;
            display: block;
        }
    }
}

.kabosu{
    padding-bottom: 120px;
    @include mq() {
        padding-bottom: 90px;
    }
    &__section{
        margin-bottom: 90px;
        @include mq() {
            margin-bottom: 60px;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    
    .profile{
        display: flex;
        flex-wrap: wrap;
        &__thumb{
            width: 560px;
            flex-basis: 560px;
            max-width: 560px;
            @include mq() {
                width: 100%;
                flex-basis: 100%;
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
        &__img{}
        &__details{
            width: calc(100% - 560px);
            flex-basis: calc(100% - 560px);
            max-width: calc(100% - 560px);
            padding-left: 40px;
            @include mq() {
                width: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-left: 0;
            }
        }
        &__table{
            border: 1px solid #e6e6e6;
            width: 100%;
        }
        &__tr{}
        &__th{
            text-align: left;
            font-size: 1.6rem;
            font-weight: bold;
            background-color: #ededed;
            padding: 17px 25px;
            border: 1px solid #e6e6e6;
            vertical-align: middle;
            @include mq() {
                padding: 7px 12px;
                font-size: 1.4rem;
                letter-spacing: -.05em;
                width: 7em;
            }
        }
        &__td{
            font-size: 1.6rem;
            padding: 17px 25px;
            background-color: #fff;
            border: 1px solid #e6e6e6;
            @include mq() {
                padding: 7px 12px;
                font-size: 1.4rem;
                letter-spacing: -.05em;
            }
            &.habit{
                color: #db0000;
                font-weight: bold;
            }
        }
        &__intro{
            margin-top: 1em;
        }
    }
    .performance{
        &__section{
            display: flex;
            margin-bottom: 60px;
            flex-wrap: wrap;
            align-items: center;
            @include mq() {
                margin-bottom: 40px;
            }
            
            &:last-of-type{
                margin-bottom: 0;
            }
            &:nth-of-type(odd){
                .performance__thumb{}
                .performance__details{}
            }
            &:nth-of-type(even){
                .performance__thumb{
                    order: 2;
                    @include mq() {
                        order: 1;
                    }
                }
                .performance__details{
                    order: 1;
                    padding-left: 0;
                    padding-right: 40px;
                    @include mq() {
                        padding-right: 0;
                        order: 2;
                    }
                }
            }
        }
        &__thumb{
            width: 580px;
            flex-basis: 580px;
            max-width: 580px;
            @include mq() {
                width: 100%;
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        &__img{}
        &__details{
            width: calc(100% - 580px);
            flex-basis: calc(100% - 580px);
            max-width: calc(100% - 580px);
            padding-left: 40px;
            @include mq() {
                width: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-left: 0;
            }
        }
        &__ttl{
            font-size: 2.8rem;
            line-height: 5.4rem;
            padding: 0 0 0 20px;
            position: relative;
            font-family: "Noto Serif JP";
            letter-spacing: .1em;
            margin-bottom: 15px;
            @include mq() {
                font-size: 2.2rem;
                line-height: 4.2rem;
                margin-top: 1.5em;
                margin-bottom: 1em;
            }
            &:before{
                background: #e7233b;
                content: "";
                left: 0;
                position: absolute;
                border-radius: 2px;
                height: 31px;
                top: 12.5px;
                width: 4px;
                @include mq() {
                    height: 100%;
                    top: 0;
                }
            }
        }
        &__para{
            line-height: 1.75;
            margin-bottom: 1.5em;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
    .video{
        display: flex;
        margin: 0 -20px -60px -20px;
        flex-wrap: wrap;
        @include mq() {
            margin: 0;
        }
        &__item{
            width: 33.333%;
            max-width: 33.333%;
            flex-basis: 33.333%;
            padding: 0 20px;
            margin-bottom: 60px;
            @include mq() {
                width: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding: 0;
                margin-bottom: 45px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
        &__pv{
            max-width: 100%;
            vertical-align: bottom;
            margin-bottom: 20px;
            @include mq() {
                margin-bottom: 15px;
            }
        }
        &__details{}
        &__ttl{
            font-size: 2.0rem;
            font-weight: bold;
            margin-bottom: .25em;
            @include mq() {
                font-size: 1.8rem;
            }
        }
        &__para{
            font-size: 1.4rem;
            line-height: 1.75;
        }
        &__bt{
            background-color: #000;
            padding: 10px 15px;
            border-radius: 50px;
            color: #fff;
            text-align: center;
            display: block;
            color: #fff;
            margin-top: 15px;
            position: relative;
            svg{
                fill: #fff;
                bottom: 0;
                height: 6px;
                margin: auto;
                position: absolute;
                right: 36px;
                top: 0;
                width: 11px;
                .path{
                    fill: none;
                    stroke: #fff;
                    stroke-miterlimit: 10;
                }
            }
        }
    }
}

.row2Ttl{
    text-align: center;
    font-family: "Noto Serif JP";
    margin-bottom: 45px;
    @include mq() {
        margin-bottom: 30px;
    }
    &:before{
        background: url(../img/img_64fhi0.png) no-repeat;
        background-size: 100% auto;
        content: "";
        display: block;
        height: 43px;
        margin: 0 auto 10px;
        width: 45px;
        @include mq() {
            height: 29px;
            width: 30px;
        }
    }
    &_type_en{
        font-size: 3.0rem;
        display: block;
        line-height: 1;
        @include mq() {
            font-size: 2.2rem;
            line-height: 2.4rem;
            margin: 0 0 5px;
        }
    }
    &_type_jp{
        font-size: 1.6rem;
        display: block;
        line-height: 1;
        margin-top: 17px;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.4rem;
            margin-top: 0;
        }
    }
}


