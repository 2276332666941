/*---------------------------------
    キービジュアル
---------------------------------*/

.section-01 {
    background: url(../img/img_the_oita_creature.jpg) no-repeat top;
    background-size: 1610px auto;
    margin: -70px 0 60px;
    padding: 100px 0 0;
    position: relative;
    @include mq() {
        background: url(../img/img_the_oita_creature_sp.jpg) no-repeat top;
        background-size: auto 513px;
        margin: -20px 0 30px;
        min-height: 513px;
        overflow: hidden;
        padding: 20px 0 0;
    }
    &__rugby {
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 996px;
        @include mq() {
            top: 20px;
            padding: 0 60px;
            width: auto;
        }
        @include mq(mobile) {
            padding: 0 20px;
        }
        }
    &__youtube {
        position: relative;
        padding-top: 56.25%;
        width: 100%;
        z-index: 10;
        iframe,
        video {
            height: 100% !important;
            position: absolute;
            right: 0;
            top: 0;
            width: 100% !important;
        }
    }
    &__container {
        @include container;
    }
    &__heading {
        align-items: center;
        background: #fff;
        border-radius: 80px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .08);
        display: flex;
        flex-direction: column;
        height: 400px;
        justify-content: space-between;
        margin: 0 auto 150px;
        padding: 70px 0 30px;
        position: relative;
        width: 380px;
        @include mq() {
            border-radius: 30px;
            height: 250px;
            margin: 0 auto 100px;
            padding: 30px 0 10px;
            width: 220px;
        }
        @include mq(mobile) {
            margin: 0 auto 120px;
        }
        &::after {
            background: url(../img/img_Jgde91.png) no-repeat;
            bottom: 80px;
            content: "";
            display: block;
            height: 81px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 416px;
            @include mq() {
                background-size: 100% auto;
                bottom: 40px;
                height: 49px;
                width: 254px;
            }
        }
    }
    &__heading-ja {
        font-family: "Noto Serif JP";
        font-size: 2.6rem;
        letter-spacing: .1em;
        line-height: 5.8rem;
        writing-mode: vertical-rl;
        @include mq() {
            font-size: 2rem;
            line-height: 2;
        }
    }
    &__heading-en {
        color: #e7233b;
        font-family: "Roboto Slab";
        font-size: 1.2rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 1rem;
        }
    }
    &__list {
        display: flex;
        justify-content: space-around;
    }
    &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: center;
        width: calc(33.3333% - 10px);
    }
    &__count {
        color: #e7233b;
        font-family: "Roboto Slab";
        font-size: 6rem;
        letter-spacing: .1em;
        line-height: 1;
        @include mq() {
            font-size: 3.8rem;
            margin: 0 0 5px;
        }
    }
    &__title {
        font-family: "Noto Serif JP";
        font-size: 1.8rem;
        letter-spacing: .1em;
        margin: 0 0 20px;
        @include mq() {
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0 0 10px;
        }
    }
    &__text {
        margin: 0 0 20px;
        @include mq() {
            display: none;
        }
    }
    &__button {
        background: #fff;
        border-radius: 24px;
        font-family: "Noto Serif JP";
        display: block;
        letter-spacing: .1em;
        margin: auto auto 0;
        padding: 8px 0;
        position: relative;
        width: 236px;
        @include mq() {
            border-radius: 18px;
            height: 36px;
            padding: 0;
            width: 36px;
        }
        svg {
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            right: 23px;
            top: 0;
            width: 11px;
            @include mq() {
                right: 0;
                left: 0;
            }
            .path {
                fill: none;
                stroke: #e7233b;
                stroke-miterlimit: 10;
            }
        }
    }
    &__button-text {
        @include mq() {
            display: none;
        }
    }
    &__breakdown-item {
        position: absolute;
        right: -40px;
        top: -40px;
        @include mq() {
            top: -100px;
        }
        @include mq(mobile) {
            right: -20px;
        }
    }
    &__breakdown-link {
        align-items: center;
        background: #fff;
        border-radius: 65px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .08);
        display: flex;
        flex-direction: column;
        height: 130px;
        justify-content: center;
        padding: 10px 0 0;
        transform: scale(1);
        transition: transform .5s;
        width: 130px;
        @include mq() {
            border-radius: 46px;
            height: 92px;
            width: 92px;
        }
        &:hover {
            transform: scale(1.125);
            // animation: none;
            opacity: 1;
        }
    }
    &__breakdown-title {
        font-size: 1.2rem;
        letter-spacing: 0;
        line-height: 2rem;
        @include mq() {
            font-size: 1rem;
            line-height: 1.6rem;
        }
        &::before {
            background: url(../img/img_79vjyk.png) no-repeat;
            background-size: 100% auto;
            content: "";
            height: 42px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: -21px;
            width: 42px;
            @include mq() {
                height: 36px;
                top: -18px;
                width: 36px;
            }
        }
    }
    &__breakdown-count {
        color: #e7233b;
        font-family: "Roboto Slab";
        font-size: 4rem;
        letter-spacing: .1em;
        line-height: 1;
        @include mq() {
            font-size: 2.4rem;
        }
    }
}

/*---------------------------------
    FOOD & PRODUCT
---------------------------------*/

.section-02 {
    background: url(../img/img_food_product_creature.png) no-repeat center top;
    background-size: 1500px auto;
    padding: 60px 0 150px;
    position: relative;
    z-index: 10;
    @include mq() {
        background: url(../img/img_food_product_creature_sp.png) no-repeat center top;
        background-size: 480px auto;
        padding: 30px 0 75px;
    }
    &::before {
        background: url(../img/img_food_product_pattern.png) no-repeat center;
        background-size: 1570px auto;
        bottom: -100px;
        content: "";
        height: 406px;
        left: 0;
        position: absolute;
        right: 0;
        @include mq() {
            background: url(../img/img_food_product_pattern_sp.png) no-repeat center;
            background-size: 726px auto;
            bottom: 15px;
            height: 89px;
        }
    }
    &__container {
        @include container;
    }
    &__heading {
        text-align: center;
        position: relative;
        @include mq() {
            margin: 0 0 25px;
        }
        &::before {
            background: url(../img/img_64fhi0.png) no-repeat;
            background-size: 100% auto;
            content: "";
            display: block;
            height: 43px;
            margin: 0 auto 10px;
            width: 45px;
            @include mq() {
                height: 29px;
                width: 30px;
            }
        }
    }
    &__heading-en {
        font-family: "Roboto Slab";
        font-size: 3rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 2.2rem;
            line-height: 2.4rem;
            margin: 0 0 5px;
        }
    }
    &__heading-ja {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.4rem;
        }
    }
    &__body {
        display: flex;
        justify-content: space-between;
        @include mq() {
            display: block;
        }
    }
    &__side {
        position: relative;
        width: 180px;
        @include mq() {
            width: auto;
        }
    }
    &__category-area {
        position: relative;
        padding: 50px 0 0;
        transition: opacity .2s, transform .2s;
        @include mq() {
            padding: 0;
        }
        &_fixed {
            position: fixed;
            top: 0;
            @include mq() {
                position: relative;
            }
        }
        &_absolute {
            bottom: 0;
            position: absolute;
            top: auto;
            @include mq() {
                position: relative;
            }
        }
        &_fade {
            opacity: 0;
            transform: translateX(-20px);
            transition: opacity .2s, transform .2s, visibility 0s .2s;
            visibility: hidden;
            @include mq() {
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
            }
        }
    }
    &__category-bookmark {
        background: #e7233b;
        height: 50px;
        left: 0;
        position: absolute;
        top: 50px;
        transform: translateY(0);
        transition: transform .4s cubic-bezier(.215, .61, .355, 1);
        width: 4px;
        @include mq() {
            display: none;
        }
    }
    &__category-list {
        padding: 0 0 50px;
        position: relative;
        @include mq() {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px -10px;
            padding: 0;
        }
        &::before {
            background: #e6e6e6;
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            top: 0;
            width: 1px;
            @include mq() {
                content: none;
            }
        }
    }
    &__category-item {
        @include mq() {
            margin: 0 5px 10px;
        }
    }
    &__category-link {
        display: block;
        font-size: 1.8rem;
        line-height: 5rem;
        overflow: hidden;
        padding: 0 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include mq() {
            border-radius: 3px;
            border-top: 3px solid transparent;
            font-size: 1.6rem;
            line-height: 3rem;
            padding: 0 10px;
        }
        &_active {
            @include mq() {
                background: #fff;
                border-top-color: #e7233b;
            }
        }
        &:hover {
            @include mq() {
                // animation: none;
                opacity: 1;
            }
        }
    }
    &__main {
        width: 1060px;
        @include mq() {
            width: auto;
        }
    }
    &__item {
        padding: 50px 0 0;
        @include mq() {
            display: none;
            padding: 20px 0 0;
        }
        &_active {
            @include mq() {
                animation: slidein .35s backwards;
                display: block;
            }
        }
    }
    &__bundle {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -19px;
        @include mq() {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0;
        }
    }
    &__article {
        margin: 0 19px 40px;
        width: calc(33.3333% - 38px);
        @include mq() {
            margin: 0 0 20px;
            width: calc(50% - 10px);
        }
    }
    &__link {
        display: block;
    }
    &__thumbnail {
        align-items: center;
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 20px;
        height: 232px;
        overflow: hidden;
        @include mq() {
            margin: 0 0 10px;
            height: calc(120 / 480 * 100vw);
        }
        @include mq(mobile) {
            height: calc(120 / 400 * 100vw);
        }
        img {
            height: 100%;
            max-width: none;
            width: auto;
        }
    }
    &__title {
        font-size: 1.8rem;
        @include mq() {
            font-size: 1.6rem;
        }
    }
    &__text {
        font-size: 1.4rem;
    }
    &__button {
        background: #000;
        border-radius: 32px;
        color: #fff;
        font-family: "Noto Serif JP";
        display: block;
        letter-spacing: .1em;
        margin: 0 auto;
        padding: 16px 0;
        position: relative;
        text-align: center;
        width: 412px;
        @include mq() {
            border-radius: 25px;
            padding: 11px 0;
            width: auto;
        }
        svg {
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            right: 36px;
            top: 0;
            width: 11px;
            .path {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
            }
        }
    }
}

/*---------------------------------
    NEWS
---------------------------------*/

.section-03 {
    background: #fff;
    padding: 90px 0;
    position: relative;
    @include mq() {
        padding: 45px 0 90px;
    }
    &::before {
        @include mq() {
            background: url(../img/img_news_pattern_sp.png) no-repeat center;
            background-size: 563px auto;
            bottom: -70px;
            content: "";
            height: 158px;
            left: 0;
            position: absolute;
            right: 0;
        }
    }
    &__container {
        @include container;
    }
    &__body {
        display: flex;
        @include mq() {
            display: block;
        }
    }
    &__side {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 250px;
        @include mq() {
            display: block;
            width: auto;
        }
    }
    &__heading {
        margin: 0 0 50px;
        @include mq() {
            margin: 0 0 25px;
            text-align: center;
        }
    }
    &__heading-en {
        font-family: "Roboto Slab";
        font-size: 3rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 2.2rem;
            line-height: 2.4rem;
            margin: 0 0 5px;
        }
    }
    &__heading-ja {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.4rem;
        }
    }
    &__button {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        padding: 0 1em 0 0;
        position: relative;
        @include mq() {
            bottom: calc(45px - .5em);
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
        svg {
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 11px;
            .path {
                fill: none;
                stroke: #e7233b;
                stroke-miterlimit: 10;
            }
        }
    }
    &__main {
        align-self: center;
        width: 990px;
        @include mq() {
            width: auto;
        }
    }
    &__article {
        &:not(:last-child) {
            margin: 0 0 30px;
            @include mq() {
                margin: 0 0 15px;
            }
        }
    }
    &__link {
        align-items: center;
        display: flex;
        justify-content: space-between;
        @include mq() {
            align-items: flex-start;
        }
    }
    &__thumbnail {
        align-items: center;
        background: #fff;
        display: flex;
        flex: none;
        flex-direction: column;
        height: 86px;
        justify-content: center;
        overflow: hidden;
        width: 86px;
        @include mq() {
            height: 72px;
            margin: 0 15px 0 0;
            width: 72px;
        }
        img {
            height: 100%;
            max-width: none;
            width: auto;
        }
    }
    &__content {
        width: 870px;
        @include mq() {
            width: auto;
        }
    }
    &__title {
        letter-spacing: .1em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include mq() {
            line-height: 2.6rem;
            white-space: normal;
        }
    }
    &__data {
        display: flex;
        flex-wrap: wrap;
    }
    &__time {
        color: #999;
        font-size: 1.5rem;
        letter-spacing: .1em;
        margin: 0 30px 0 0;
        @include mq() {
            font-size: 1.3rem;
            margin: 0 1em 0 0;
        }
    }
    &__category {
        color: #999;
        font-size: 1.5rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 1.3rem;
        }
        &:not(:first-of-type)::before {
            content: ",";
            margin: 0 .25em 0 0;
        }
    }
}

/*---------------------------------
    CAFE & RESTRANT
---------------------------------*/

.section-04 {
    background: url(../img/img_cafe_restrant_creature.png) no-repeat top, url(../img/img_cafe_restrant_pattern.png) repeat-x bottom;
    background-size: 1500px auto, auto 649px;
    padding: 150px 0 120px;
    position: relative;
    @include mq() {
        background: url(../img/img_cafe_restrant_creature_sp.png) no-repeat top, url(../img/img_cafe_restrant_pattern_sp.png) repeat-x bottom;
        background-size: 480px auto, auto 425px;
        padding: 40px 0 50px;
    }
    &__container {
        @include container;
    }
    &__heading {
        margin: 0 0 50px;
        text-align: center;
        position: relative;
        @include mq() {
            margin: 0 0 25px;
        }
        &::before {
            background: url(../img/img_64fhi0.png) no-repeat;
            background-size: 100% auto;
            content: "";
            display: block;
            height: 43px;
            margin: 0 auto 10px;
            width: 45px;
            @include mq() {
                height: 29px;
                width: 30px;
            }
        }
    }
    &__heading-en {
        font-family: "Roboto Slab";
        font-size: 3rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 2.2rem;
            line-height: 2.4rem;
            margin: 0 0 5px;
        }
    }
    &__heading-ja {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.4rem;
        }
    }
    &__sp {
        display: none;
        @include mq() {
            display: block;
        }
    }
    &__list {
        display: flex;
        justify-content: center;
        margin: 0 -40px 70px;
        @include mq() {
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0;
        }
    }
    &__article {
        margin: 0 40px;
        width: 360px;
        @include mq() {
            margin: 0 0 30px;
            width: calc(50% - 10px);
        }
        &:last-child {
            display: none;
            @include mq() {
                display: block;
            }
        }
    }
    &__link {
        display: block;
        position: relative;
    }
    &__bistro-oita {
        background: url(../img/img_79vjyk.png) no-repeat;
        background-size: 100% auto;
        height: 62px;
        left: -31px;
        position: absolute;
        top: -32px;
        width: 62px;
        @include mq() {
            height: 40px;
            left: -20px;
            top: -20px;
            width: 40px;
        }
    }
    &__thumbnail {
        align-items: center;
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        height: 280px;
        justify-content: center;
        margin: 0 0 20px;
        overflow: hidden;
        @include mq() {
            margin: 0 0 10px;
            height: calc(132 / 480 * 100vw);
        }
        @include mq(mobile) {
            height: calc(132 / 400 * 100vw);
        }
        img {
            height: 100%;
            max-width: none;
            width: auto;
        }
    }
    &__title {
        font-size: 1.8rem;
        @include mq() {
            font-size: 1.6rem;
            line-height: 2.8rem;
        }
    }
    &__address {
        color: #999;
        font-size: 1.4rem;
        margin: 0 0 10px;
        @include mq() {
            margin: 0;
        }
    }
    &__text {
        font-size: 1.4rem;
    }
    &__group {
        align-items: center;
        display: flex;
        justify-content: center;
        @include mq() {
            display: block;
        }
    }
    &__button {
        align-items: center;
        background: #000;
        border-radius: 40px;
        color: #fff;
        display: flex;
        font-family: "Noto Serif JP";
        height: 80px;
        justify-content: center;
        letter-spacing: .1em;
        margin: 0 20px;
        position: relative;
        width: 412px;
        @include mq() {
            border-radius: 25px;
            height: 50px;
            margin: 0;
            width: auto;
        }
        &:not(:last-child) {
            @include mq() {
                margin: 0 0 20px;
            }
        }
        &_theme {
            &_white {
                background: #fff;
                color: inherit;
            }
        }
        svg {
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            right: 36px;
            top: 0;
            width: 11px;
            .path {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
            }
        }
        img {
            width: 241px;
            @include mq() {
                width: 165px;
            }
        }
    }
}

/*---------------------------------
    ABOUT US
---------------------------------*/

.section-05 {
    background: url(../img/img_about_us_creature.png) no-repeat top;
    background-size: 1500px auto;
    padding: 160px 0 150px;
    position: relative;
    @include mq() {
        background: url(../img/img_about_us_creature_sp.png) no-repeat top;
        background-size: 552px auto;
        padding: 40px 0 60px;
    }
    &__container {
        @include container;
    }
    &__heading {
        margin: 0 0 50px;
        text-align: center;
        position: relative;
        @include mq() {
            margin: 0 0 25px;
        }
        &::before {
            background: url(../img/img_64fhi0.png) no-repeat;
            background-size: 100% auto;
            content: "";
            display: block;
            height: 43px;
            margin: 0 auto 10px;
            width: 45px;
            @include mq() {
                height: 29px;
                width: 30px;
            }
        }
    }
    &__heading-en {
        font-family: "Roboto Slab";
        font-size: 3rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 2.2rem;
            line-height: 2.4rem;
            margin: 0 0 5px;
        }
    }
    &__heading-ja {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        @include mq() {
            line-height: 2.4rem;
        }
    }
    &__slogan {
        font-family: "Noto Serif JP";
        font-size: 2.8rem;
        letter-spacing: .1em;
        margin: 0 0 60px;
        text-align: center;
        @include mq() {
            font-size: 2.2rem;
            line-height: 4.6rem;
            margin: 0 0 30px;
        }
    }
    &__underline {
        background: url(../img/img_underline.png) repeat-x bottom left;
        background-size: auto 9px;
        padding: 0 0 .5em;
    }
    &__sp {
        display: none;
        @include mq() {
            display: block;
        }
    }
    &__content {
        min-height: 533px;
        position: relative;
        width: 590px;
        @include mq() {
            min-height: 0;
            width: auto;
        }
        &::before {
            background: url(../img/img_about_us.jpg) no-repeat center left;
            background-size: cover;
            content: "";
            display: block;
            height: 100%;
            left: 650px;
            min-width: 630px;
            position: absolute;
            top: 0;
            width: calc(50vw - 30px);
            @include mq() {
                background-position: center;
                height: 320px;
                margin: 0 -60px 20px;
                min-width: 0;
                position: static;
                width: auto;
            }
            @include mq(mobile) {
                margin: 0 -20px 20px;
            }
        }
    }
    &__text {
        margin: 0 0 60px;
        text-align: justify;
        @include mq() {
            margin: 0 0 30px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -15px -15px 0;
        @include mq() {
            display: block;
            margin: 0;
        }
    }
    &__item {
        margin: 15px;
        width: 280px;
        @include mq() {
            margin: 0 0 20px;
            width: auto;
        }
        &:last-child {
            @include mq() {
                margin: 0;
            }
        }
    }
    &__link {
        background: #000;
        border-radius: 10px;
        color: #fff;
        display: block;
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        line-height: 2.8rem;
        padding: 18.5px 30px;
        position: relative;
        text-indent: -.5em;
        &:last-child {
            line-height: 2.4rem;
            padding: 11px 0;
            text-align: center;
        }
        svg {
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            right: 21px;
            top: 0;
            width: 11px;
            .path {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
            }
        }
    }
}

/*---------------------------------
    INSTAGRAM
---------------------------------*/

.section-06 {
    background: url(../img/img_instagram_pattern.png) no-repeat top;
    background-size: 1711px auto;
    padding: 20px 0 90px;
    position: relative;
    @include mq() {
        background: url(../img/img_instagram_pattern_sp.png) no-repeat bottom 30px center;
        background-size: 586px auto;
        padding: 0 0 35px;
    }
    &__container {
        @include container;
    }
    &__heading {
        margin: 0 0 50px;
        text-align: center;
        position: relative;
        @include mq() {
            margin: 0 0 25px;
        }
        &::before {
            background: url(../img/img_instagram_red.svg) no-repeat;
            background-size: 100% auto;
            content: "";
            display: block;
            height: 41px;
            margin: 0 auto 10px;
            width: 41px;
            @include mq() {
                height: 29px;
                width: 29px;
            }
        }
    }
    &__heading-en {
        font-family: "Roboto Slab";
        font-size: 3rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 2.2rem;
            line-height: 2.4rem;
            margin: 0 0 5px;
        }
    }
    &__heading-ja {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        @include mq() {
            line-height: 2.4rem;
        }
    }
    &__sp {
        display: none;
        @include mq() {
            display: block;
        }
    }
    &__slider {
        margin: 0 0 140px;
        visibility: hidden;
        @include mq() {
            margin: 0 0 40px;
        }
        &.slick-initialized {
            visibility: visible;
        }
    }
    &__link {
        display: block;
        pointer-events: none;
        width: 248px;
        @include mq() {
            width: 120px;
        }
    }
    .slick-initialized .slick-slide {
        display: flex;
        flex-direction: column;
        font-size: 0;
        &:focus {
            outline: none;
        }
    }
}

.banner {
    &__container {
        @include container;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -20px;
        @include mq() {
            justify-content: space-between;
            margin: 0;
        }
    }
    &__item {
        margin: 0 20px 40px;
        width: 216px;
        @include mq() {
            margin: 0 0 20px;
            width: calc(50% - 10px);
        }
    }
    &__link {
        background: #fff;
        border: 1px solid #e6e6e6;
        display: block;
        padding: 7px;
        @include mq() {
            padding: 3px;
        }
    }
}
