/*---------------------------------
    とよの食彩愛用店
---------------------------------*/

.restrant {
    &__slider {
        margin: 0 0 30px;
        padding: 0 105px;
        position: relative;
        visibility: hidden;
        @include mq() {
            margin: 0 0 15px;
            padding: 0;
        }
        &.slick-initialized {
            visibility: visible;
        }
    }
    .slick-slider {
        .slick-arrow {
            -webkit-appearance: none;
            align-items: center;
            background: #000;
            border: none;
            border-radius: 30px;
            bottom: 0;
            display: flex;
            height: 60px;
            justify-content: center;
            margin: auto;
            padding: 0;
            position: absolute;
            top: 0;
            width: 60px;
            z-index: 10;
            @include mq() {
                height: 30px;
                width: 30px;
            }
            &.pc {
                @include mq() {
                    display: none;
                }
            }
            &.sp {
                display: none;
                @include mq() {
                    display: block;
                }
            }
            svg {
                height: 16px;
                width: 11px;
                @include mq() {
                    height: 9px;
                    width: 6px;
                }
                .a {
                    fill: none;
                    stroke: #fff;
                    stroke-miterlimit: 10;
                }
            }
        }
        .slick-prev {
            left: 0;
            @include mq() {
                border-radius: 0 6px 6px 0;
            }
        }
        .slick-next {
            right: 0;
            @include mq() {
                border-radius: 6px 0 0 6px;
            }
        }
    }
    &__text {
        font-size: 1.8rem;
        margin: 0 0 60px;
        @include mq() {
            font-size: 1.4rem;
            margin:  0 0 30px;
        }
    }
    &__title {
        font-family: "Noto Serif JP";
        font-size: 2.8rem;
        letter-spacing: .1em;
        line-height: 5.4rem;
        margin: 0 0 30px;
        padding: 0 0 0 20px;
        position: relative;
        @include mq() {
            font-size: 2.2rem;
            line-height: 4.2rem;
            margin: 0 0 15px;
        }
        &::before {
            background: #e7233b;
            border-radius: 2px;
            content: "";
            height: 31px;
            left: 0;
            position: absolute;
            top: 12.5px;
            width: 4px;
            @include mq() {
                height: 27px;
                top: 7.5px;
            }
        }
    }
    &__list {
        margin: 0 0 90px;
        @include mq() {
            margin: 0 0 45px;
        }
    }
    &__item {
        align-items: flex-end;
        background: #fff;
        border-radius: 10px;
        display: flex;
        margin: 0 0 20px;
        padding: 24px 48px;
        @include mq() {
            border-radius: 6px;
            display: block;
            padding: 11px 20px;
        }
    }
    &__name {
        font-size: 1.8rem;
        margin: 0 2em 0 0;
        padding: 0 0 0 14px;
        position: relative;
        @include mq() {
            font-size: 1.6rem;
            margin: 0;
        }
        &::before {
            background: #e7233b;
            border-radius: 3px;
            content: "";
            height: 6px;
            left: 0;
            position: absolute;
            top: 15px;
            width: 6px;
            @include mq() {
                top: 13px;
            }
        }
    }
    &__value {
        flex: none;
        font-size: 1.8rem;
        font-weight: bold;
        margin: 0 0 0 auto;
        @include mq() {
            font-size: 1.6rem;
            text-align: right;
        }
    }
    &__subtitle {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        margin: 0 0 30px;
        padding: 0 0 0 20px;
        position: relative;
        font-size: 2.6rem;
        @include mq() {
            font-size: 2rem;
            margin: 0 0 15px;
        }
        &::before {
            background: #e7233b;
            border-radius: 3px;
            content: "";
            height: 6px;
            left: 0;
            position: absolute;
            top: 23px;
            width: 6px;
            @include mq() {
                top: 17px;
            }
        }
    }
    &__table {
        margin: 0 0 90px;
        width: 100%;
        @include mq() {
            border-bottom: 1px solid #e6e6e6;
            display: block;
            margin: 0 0 30px;
        }
    }
    &__tbody,
    &__tr {
        @include mq() {
            display: block;
        }
    }
    &__th,
    &__td {
        border: 1px solid #e6e6e6;
        @include mq() {
            border-color: transparent;
            display: block;
        }
    }
    &__th {
        background: #ededed;
        font-weight: bold;
        padding: 16px 22px;
        text-align: left;
        width: 208px;
        @include mq() {
            border-top-color: #e6e6e6;
            padding: 2.5px 1em;
            width: auto;
        }
    }
    &__td {
        overflow: hidden;
        padding: 16px 26px;
        @include mq() {
            padding: 5px 1em;
        }
    }
    &__link {
        display: inline-block;
        text-decoration: underline;
    }
    &__arrya {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 -10px;
        @include mq() {
            margin: 0 0 -5px;
        }
    }
    &__amenity {
        background: #fff;
        border-radius: 3px;
        font-size: 1.5rem;
        letter-spacing: .1em;
        line-height: 2.8rem;
        margin: 0 0 10px;
        padding: 0 .5em;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.3rem;
            margin: 0 0 5px;
        }
        &:not(:last-of-type) {
            margin: 0 10px 10px 0;
            @include mq() {
                margin: 0 5px 5px 0;
            }
        }
    }
    &__google {
        margin: 0 0 90px;
        position: relative;
        padding-top: 56.25%;
        width: 100%;
        z-index: 10;
        @include mq() {
            margin: 0 0 45px;
            height: 500px;
            padding-top: 0;
        }
        iframe {
            height: 100% !important;
            position: absolute;
            right: 0;
            top: 0;
            width: 100% !important;
        }
    }
}
