/*---------------------------------
    レシピ
---------------------------------*/
.recipe{
    &Youtube{
        margin-top: -45px;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
        margin-bottom: 30px;

        @include mq() {
            margin-top: 0;
            margin-bottom: 18px;
        }

        iframe{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__intro{
        margin-bottom: 60px;
        @include mq() {
            margin-bottom: 45px;
        }
    }
    &__material{
        margin-bottom: 60px;
        @include mq() {
            margin-bottom: 45px;
        }
    }
    &__howto{
        margin-bottom: 60px;
        @include mq() {
            margin-bottom: 45px;
        }
    }
}

.recipeCats{
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    @include mq() {
        justify-content: flex-start;
        margin-bottom: 40px;
        max-width: 100vw;
        overflow: scroll;
    }
    &__item{
        padding: 10px;
        @include mq() {
            padding: 5px;
        }
    }
    &__link{
        width: 240px;
        max-width: 240px;
        flex-basis: 240px;
        border-radius: 50px;
        padding: 10px 15px;
        background-color: #fff;
        color: #000;
        display: block;
        text-align: center;
        position: relative;
        border: 1px solid #eee;
        @include mq() {
            width: 146px;
            max-width: 146px;
            flex-basis: 146px;
            padding: 7px 12px;
        }

        svg{
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            right: 36px;
            top: 0;
            width: 11px;
            @include mq() {
                right: 15px;
            }
            .path{
                fill: none;
                stroke: #000;
                stroke-miterlimit: 10;
            }
        }
    }
}

.recipeList{
    &__subTtl{
        margin-bottom: 1.5em;
        @include mq() {
            font-size: 1.9rem;
        }
    }
    &__bt{
        margin-top: -30px;
        margin-bottom: 90px;
    }
}

.hierarchy__title{
    display: flex;
    justify-content: center;
    text-align: left;
    @include mq() {
        line-height: 1.35;
    }
}

.editor{
    .discList{
        background: #fff;
        padding: 20px 30px;
        border-radius: 6px;

        @include mq() {
            padding: 15px 25px;
        }
    }
    
    .numList{
        &__item{
            border-radius: 6px;
            background: #fff;
            padding: 15px 30px 15px 50px;

            @include mq() {
                padding: 15px 20px 15px 40px;
            }
        }
    }
}

.cards{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @include mq() {
        margin: 0;
    }
    &__item{
        padding: 0 10px;
        width: 33.333%;
        max-width: 33.333%;
        flex-basis: 33.333%;
        position: relative;
        @include mq() {
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
            padding: 0;
            margin-bottom: 30px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
    &__thumbnail{
        margin-bottom: 8px;
        img{
            border-radius: 6px;
        }
    }
    &__array{
        display: flex;
    }
    &__category{
        background: #ffdf53;
        border-radius: 3px;
        font-size: 1.4rem;
        left: -1em;
        letter-spacing: .1em;
        max-width: 100%;
        overflow: hidden;
        padding: 0 1em;
        position: absolute;
        text-overflow: ellipsis;
        top: -13px;
        white-space: nowrap;
        z-index: 10;
        margin: 5px;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.3rem;
            top: -11.5px;
        }
    }
}