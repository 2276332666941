/*---------------------------------
    メディアクエリ
---------------------------------*/

$responsive: (
    'mobile': 'screen and (max-width: 425px)',
    'tablet': 'screen and (max-width: 768px)',
    'laptop': 'screen and (max-width: 1024px)',
    '4k': 'screen and (max-width: 2560px)',
) !default;

@mixin mq($breakpoint: tablet) {
    @media #{map-get($responsive, $breakpoint)} {
        @content;
    }
}
