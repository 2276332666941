/*---------------------------------
    コンテナ
---------------------------------*/

@mixin container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
    padding: 0 40px;
    @include mq() {
        padding: 0 60px;
    }
    @include mq(mobile) {
        padding: 0 20px;
    }
}

/*---------------------------------
    レイアウト
---------------------------------*/

.container {
    @include container;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    @include mq() {
        display: block;
    }
    &_col_1{
        display: block;
    }
}

.content {
    width: 940px;
    @include mq() {
        width: auto;
    }
    &_width {
        &_auto {
            width: 100%;
        }
    }
}

/*---------------------------------
    サイドバー
---------------------------------*/

.sidebar {
    margin: 0 0 120px;
    position: relative;
    width: 240px;
    @include mq() {
        margin: 0 0 60px;
        width: auto;
    }
    &::before,
    &::after {
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        top: 0;
    }
    &::before {
        background: #000;
        width: 100%;
    }
    &::after {
        background: #e7233b;
        width: 25%;
    }
    &__item {
        margin: auto;
        width: 240px;
        &:not(:last-child) {
            margin: 0 auto 15px;
        }
    }
    &__link {
        display: block;
    }
}

.taxonomy {
    margin: 0 0 30px;
    &__title {
        font-family: "Noto Serif JP";
        font-size: 2.2rem;
        letter-spacing: .1em;
        line-height: 3.2rem;
        margin: 0 0 15px;
        padding: 30px 0 0;
        white-space: nowrap;
        &:not(:first-of-type) {
            border-top: 1px solid #e6e6e6;
            margin: 30px 0 15px;
        }
        &_font-size {
            &_small {
                font-size: 1.8rem;
            }
        }
    }
    &__list {
        margin: 0 0 30px;
    }
    &__item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
    &__link {
        display: block;
        padding: 0 0 0 12px;
        position: relative;
        &::before {
            background: #e7233b;
            border-radius: 2px;
            content: "";
            height: 4px;
            left: 0;
            position: absolute;
            top: 14px;
            width: 4px;
        }
    }
    &__child-item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
    &__child-link {
        display: block;
        font-size: 1.4rem;
        margin: 0 0 0 2em;
    }
    &__form-control {
        -webkit-appearance: none;
        background: none;
        border: none;
        padding: 0;
        &_checkbox {
            display: none;
        }
        &_submit {
            background: #e7233b;
            border-radius: 4px;
            color: #fff;
            font-size: 1.6rem;
            font-weight: bold;
            display: block;
            height: 50px;
            width: 100%;
        }
    }
    &__form-checkbox {
        display: inline-block;
        padding: 0 0 0 27px;
        position: relative;
        &::before {
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 2px;
            content: "";
            height: 16px;
            left: 0;
            position: absolute;
            top: 8px;
            width: 16px;
            @include mq() {
                top: 6px;
            }
        }
    }
    &__form-control_checkbox:checked + &__form-checkbox::after {
        border-bottom: 2px solid #e7233b;
        border-left: 2px solid #e7233b;
        content: "";
        height: 7px;
        left: 4px;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 16px;
    }
}

/*---------------------------------
    検索システム
---------------------------------*/

.search {
    background: #000;
    border: 5px solid #000;
    border-radius: 6px;
    margin: 0 0 100px;
    @include mq() {
        border-width: 3px;
        margin: 0 0 50px;
    }
    &__intro{
        margin-top: -1.5em;
        margin-bottom: 1.5em;
        
        @include mq() {
            margin-top: -1em;
            line-height: 1.5;
        }
    }
    &__button {
        align-items: center;
        background: #000;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-family: "Noto Serif JP";
        font-size: 2rem;
        height: 80px;
        justify-content: center;
        letter-spacing: .1em;
        text-align: center;
        @include mq() {
            height: 60px;
        }
    }
    &__seal {
        background: #e7233b;
        border-radius: 15px;
        display: block;
        height: 30px;
        margin: 0 20px 0 0;
        position: relative;
        width: 30px;
        @include mq() {
            height: 26px;
            margin: 0 10px 0 0;
            width: 26px;
        }
        &::before,
        &::after {
            background: #fff;
            bottom: 0;
            content: "";
            height: 2px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 12px;
        }
        &::after {
            transition: transform .3s;
            transform: rotate(-270deg);
        }
        &_active {
            &::after {
                transform: rotate(0);
            }
        }
    }
    &__content {
        background: #fff;
        display: none;
        padding: 0 50px 50px;
        @include mq() {
            padding: 0 27px 27px;
        }
    }
    &__form-item {
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        @include mq() {
            align-items: flex-start;
            flex-direction: column;
            padding: 20px 0;
        }
    }
    &__form-title {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 3.2rem;
        padding: 0 0 0 16px;
        position: relative;
        width: 200px;
        @include mq() {
            font-size: 1.6rem;
            margin: 0 0 5px;
            width: 100%;
        }
        &::before {
            background: #e7233b;
            border-radius: 6px;
            content: "";
            height: 6px;
            left: 0;
            position: absolute;
            top: 13px;
            width: 6px;
        }
    }
    &__form-group {
        display: flex;
        flex-wrap: wrap;
        width: 900px;
        @include mq() {
            width: 100%;
        }
    }
    &__form-label {
        margin: 0 27px 0 0;
        @include mq() {
            margin: 0 1em 0 0;
        }
    }
    &__form-control {
        -webkit-appearance: none;
        background: none;
        border: none;
        padding: 0;
        &_text {
            background: #f2f2f2;
            border-radius: 4px;
            height: 68px;
            padding: 0 40px;
            width: 100%;
            @include mq() {
                height: 50px;
                padding: 0 20px;
            }
            &::placeholder {
                color: #b3b3b3;
            }
        }
        &_checkbox {
            display: none;
        }
        &_submit {
            background: #e7233b;
            border-radius: 6px;
            color: #fff;
            font-size: 1.8rem;
            font-weight: bold;
            display: block;
            height: 80px;
            margin: 50px auto 0;
            max-width: 360px;
            width: 100%;
            @include mq() {
                border-radius: 4px;
                font-size: 1.6rem;
                height: 50px;
                margin: 20px auto 0;
            }
        }
    }
    &__form-checkbox {
        display: inline-block;
        padding: 0 0 0 27px;
        position: relative;
        &::before {
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 2px;
            content: "";
            height: 16px;
            left: 0;
            position: absolute;
            top: 8px;
            width: 16px;
            @include mq() {
                top: 6px;
            }
        }
    }
    &__form-control_checkbox:checked + &__form-checkbox::after {
        border-bottom: 2px solid #e7233b;
        border-left: 2px solid #e7233b;
        content: "";
        height: 7px;
        left: 4px;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 16px;
        @include mq() {
            top: 6px;
        }
    }
}

/*---------------------------------
    多言語化プラグイン
---------------------------------*/

.taxonomy ul.bogo-language-switcher {
    li {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 0 0 0 12px !important;
        position: relative;
        &::before {
            background: #e7233b;
            border-radius: 2px;
            content: "";
            height: 4px;
            left: 0;
            position: absolute;
            top: 14px;
            width: 4px;
        }
    }
    li.current {
        color:  #aaa;
    }
    a {
        display: block;
    }
}

.nav ul.bogo-language-switcher {
    @include mq() {
        margin: 0 0 5px;
    }
    li {
        align-items: flex-start;
        color: #fff;
        display: flex;
        flex-direction: column;
        font-family: "Noto Serif JP";
        font-size: 1.5rem;
        margin: 0 0 0 20px;
        padding: 0 0 0 17px;
        position: relative;
        @include mq() {
            line-height: 2.5rem;
        }
        &::before {
            background: #e7233b;
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            top: 14.5px;
            width: 10px;
            @include mq() {
                top: 13px;
            }
        }
    }
    li.current {
        color:  #aaa;
    }
    a {
        display: block;
        color: #fff;
    }
}

/*---------------------------------
    見出し
---------------------------------*/

.hierarchy {
    min-height: 160px;
    padding: 0 0 30px;
    text-align: center;
    @include mq() {
        min-height: 0;
    }
    &__title {
        background: url(../img/img_underline_short.png) no-repeat bottom center;
        background-size: auto 4px;
        font-family: "Noto Serif JP";
        font-size: 3rem;
        line-height: 5.4rem;
        margin: 0 0 9px;
        padding: 0 0 9px;
        position: relative;
        @include mq() {
            font-size: 2.4rem;
            line-height: 4.3rem;
        }
        &_margin-bottom {
            &_large {
                margin: 0 0 19px;
                @include mq() {
                    margin: 0 0 9px;
                }
            }
        }
        &_bistro {
            background: none;
            margin: 0 auto 50px;
            padding: 0;
            width: 220px;
            @include mq() {
                margin: 0 auto;
                width: 110px;
            }
        }
    }
    &__text {
        display: block;
        font-family: "Roboto Slab";
        font-size: 1.5rem;
        @include mq() {
            font-size: 1.3rem;
        }
    }
    &__array {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__tag {
        color: #999;
        line-height: 2.8rem;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.3rem;
        }
        &:not(:last-child) {
            margin: 0 1em 0 0;
        }
        &::before {
            content: "#";
        }
    }
    &__category {
        background: #fff;
        border-radius: 3px;
        font-size: 1.5rem;
        line-height: 2.8rem;
        margin: 10px 0 0;
        padding: 0 1em;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.3rem;
            margin: 5px 0 0;
            padding: 0 .5em;
        }
        &:not(:last-child) {
            margin: 10px 10px 0 0;
            @include mq() {
                margin: 5px 5px 0 0;
            }
        }
    }
}

/*---------------------------------
    投稿タイプ一覧
---------------------------------*/

.post-list {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -19px 38px;
        @include mq() {
            justify-content: space-between;
            margin: 0 0 40px;
        }
        &_margin {
            &_large {
                margin: 0 -25px 50px;
                @include mq() {
                    margin: 0 0 40px;
                }
            }
        }
    }
    &__item {
        margin: 0 19px 38px;
        width: calc(33.3333% - 38px);
        @include mq() {
            margin: 0 0 20px;
            width: calc(50% - 10px);
        }
        &_margin {
            &_large {
                margin: 0 25px 50px;
                width: calc(33.3333% - 50px);
                @include mq() {
                    margin: 0 0 20px;
                    width: calc(50% - 10px);
                }
            }
        }
    }
    &__text {
        font-size: 1.8rem;
        line-height: 3.2rem;
        margin: 0 0 60px;
        text-align: center;
        @include mq() {
            font-size: 1.6rem;
            margin: 0 0 30px;
        }
    }
}

/*---------------------------------
    投稿タイプ詳細
---------------------------------*/

.post-detail {
    position: relative;
    &__link {
        display: block;
    }
    &__bistro-oita {
        background: url(../img/img_79vjyk.png) no-repeat;
        background-size: 100% auto;
        height: 50px;
        left: -25px;
        position: absolute;
        top: -25px;
        width: 50px;
        @include mq() {
            height: 40px;
            left: -20px;
            top: -20px;
            width: 40px;
        }
    }
    &__category {
        background: #fff;
        border-radius: 3px;
        font-size: 1.4rem;
        left: -1em;
        letter-spacing: .1em;
        max-width: 100%;
        overflow: hidden;
        padding: 0 1em;
        position: absolute;
        text-overflow: ellipsis;
        top: -13px;
        white-space: nowrap;
        z-index: 10;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.3rem;
            top: -11.5px;
        }
        &_general {
            background: #ffdf53;
        }
        &_event {
            background: #bfdbeb;
        }
    }
    &__title {
        &_font-size {
            &_large {
                font-size: 1.8rem;
                line-height: 3.2rem;
                @include mq() {
                    font-size: 1.6rem;
                    line-height: 1.5;
                }
            }
        }
        &_font-weight {
            &_bold {
                font-weight: bold;
                @include mq() {
                    line-height: 2.8rem;
                    line-height: 1.5;
                }
            }
        }
    }
    &__address {
        color: #999;
        font-size: 1.4rem;
        line-height: 3.2rem;
    }
    &__text {
        font-size: 1.4rem;
        &_detail {
            font-size: 1.8rem;
            margin: 0 0 150px;
            @include mq() {
                font-size: 1.4rem;
                margin: 0 0 30px;
            }
        }
        &_pc {
            @include mq() {
                display: none;
            }
        }
    }
    &__thumbnail {
        align-items: center;
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        height: 210px;
        justify-content: center;
        margin: 0 0 10px;
        overflow: hidden;
        @include mq() {
            height: calc(120 / 480 * 100vw);
        }
        @include mq(mobile) {
            height: calc(120 / 400 * 100vw);
        }
        a {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100%;
            min-width: 100%;
        }
        img {
            height: 100%;
            max-width: none;
            width: auto;
        }
        &_detail {
            display: block;
            margin: 0 0 50px;
            height: auto;
            @include mq() {
                margin: 0 0 25px;
            }
            img {
                max-width: 100%;
                height: auto;
                width: 100%;
            }
        }
        &_max-height {
            &_large {
                height: 280px;
                @include mq() {
                    height: calc(140 / 480 * 100vw);
                }
                @include mq(mobile) {
                    height: calc(140 / 400 * 100vw);
                }
            }
            &_xlarge {
                height: 290px;
                @include mq() {
                    height: calc(132 / 480 * 100vw);
                }
                @include mq(mobile) {
                    height: calc(132 / 400 * 100vw);
                }
            }
        }
    }
    &__array {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
    }
    &__tag {
        color: #999;
        font-size: 1.4rem;
        line-height: 2.4rem;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.2rem;
        }
        &:not(:last-child) {
            margin: 0 .5em 0 0;
        }
        &::before {
            content: "#";
        }
    }
    &__time {
        color: #999;
        display: block;
        font-size: 1.5rem;
        letter-spacing: .1em;
        line-height: 2.4rem;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.2rem;
        }
    }
    &__price {
        font-family: "Roboto Slab";
        font-size: 2rem;
        letter-spacing: .1em;
        line-height: 3.2rem;
        @include mq() {
            line-height: 2.8rem;
            font-size: 1.4rem;
            line-height: 1.5;
        }
    }
    &__tax {
        font-size: 1.4rem;
        line-height: 2.8rem;
        @include mq() {
            font-size: 1.2rem;
            line-height: 1.5;
        }
    }
    &__capacity {
        font-size: 1.4rem;
        line-height: 2.8rem;
        @include mq() {
            font-size: 1.3rem;
            line-height: 2.4rem;
        }
    }
}

/*---------------------------------
    ページネーション
---------------------------------*/

.pagination {
    margin: 0 0 120px;
    @include mq() {
        margin: 0 0 45px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &_detail {
            justify-content: space-between;
        }
    }
    &__link {
        align-items: center;
        background: #000;
        border-radius: 3px;
        color: #fff;
        display: flex;
        font-family: "Roboto Slab";
        height: 57px;
        justify-content: center;
        margin: 0 5px;
        position: relative;
        width: 64px;
        @include mq() {
            height: 40px;
            margin: 0 2.5px 5px;
            width: 40px;
        }
        &_active {
            background: #fff;
            color: #000;
            pointer-events: none;
            &::before {
                background: #e7233b;
                border-radius: 5px;
                content: "";
                height: 10px;
                left: 0;
                margin: auto;
                right: 0;
                position: absolute;
                top: -5px;
                width: 10px;
                @include mq() {
                    border-radius: 3px;
                    height: 6px;
                    top: -3px;
                    width: 6px;
                }
            }
        }
        &_prev {
            border-radius: 28.5px 4px 4px 28.5px;
            margin: 0 15px 0 0;
            width: 114px;
            @include mq() {
                margin: 0 12.5px 0 0;
                width: 54px;
            }
            &::before {
                content: none;
            }
        }
        &_back-to-list {
            width: 304px;
            @include mq() {
                width: 140px;
            }
        }
        &_next {
            border-radius: 4px 28.5px 28.5px 4px;
            margin: 0 0 0 15px;
            width: 114px;
            @include mq() {
                margin: 0 0 0 12.5px;
                width: 54px;
            }
            &::before {
                content: none;
            }
        }
        &_prev.pagination__link_active,
        &_next.pagination__link_active {
            background: #ddd;
            color: #aaa;
        }
        svg {
            display: none;
            @include mq() {
                bottom: 0;
                display: block;
                height: 6px;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                width: 11px;
            }
            .path {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
            }
        }
    }
    &__text {
        color: inherit;
        @include mq() {
            display: none;
        }
    }
}

/*---------------------------------
    関連する投稿
---------------------------------*/

.related {
    margin: 0 0 120px;
    @include mq() {
        margin: 0 0 50px;
    }
    &__heading {
        border-top: 1px solid #e6e6e6;
        font-family: "Noto Serif JP";
        font-size: 2.6rem;
        letter-spacing: .1em;
        margin: 0 0 30px;
        padding: 60px 0 0 20px;
        position: relative;
        @include mq() {
            font-size: 2rem;
            padding: 30px 0 0 15px;
        }
        &:not(:first-of-type) {
            margin: 60px 0 30px;
            @include mq() {
                margin: 50px 0 30px;
            }
        }
        &::before {
            background: #e7233b;
            border-radius: 3px;
            content: "";
            height: 6px;
            left: 0;
            position: absolute;
            top: 83px;
            width: 6px;
            @include mq() {
                top: 47px;
            }
        }
    }
    &__list {
        margin: 0 0 60px;
        @include mq() {
            margin: 0 0 30px;
        }
    }
    &__item {
        margin: 0 0 30px;
        @include mq() {
            margin: 0 0 15px;
        }
    }
    &__link {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;
        @include mq() {
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    &__bistro-oita {
        background: url(../img/img_79vjyk.png) no-repeat;
        background-size: 100% auto;
        height: 40px;
        left: -20px;
        position: absolute;
        top: -20px;
        width: 40px;
    }
    &__thumbnail {
        align-items: center;
        background: #fff;
        display: flex;
        flex: none;
        flex-direction: column;
        height: 86px;
        justify-content: center;
        overflow: hidden;
        width: 86px;
        @include mq() {
            margin: 0 15px 0 0;
            height: 72px;
            width: 72px;
        }
        img {
            height: 100%;
            max-width: none;
            width: auto;
        }
    }
    &__content {
        width: 824px;
        @include mq() {
            width: auto;
        }
    }
    &__title {
        letter-spacing: .1em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include mq() {
            line-height: 2.6rem;
            white-space: normal;
        }
        &_font-size {
            &_large {
                font-size: 1.8rem;
                line-height: 3.2rem;
                @include mq() {
                    font-size: 1.6rem;
                    line-height: 2.6rem;
                }
            }
        }
    }
    &__text {
        font-size: 1.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include mq() {
            white-space: normal;
        }
    }
    &__array {
        display: flex;
        flex-wrap: wrap;
    }
    &__time {
        color: #999;
        font-size: 1.5rem;
        letter-spacing: .1em;
        margin: 0 30px 0 0;
        @include mq() {
            font-size: 1.3rem;
            margin: 0 1em 0 0;
        }
    }
    &__category {
        color: #999;
        font-size: 1.5rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 1.3rem;
        }
        &:not(:first-of-type)::before {
            content: ",";
            margin: 0 .25em 0 0;
        }
    }
    &__button {
        background: #000;
        border-radius: 32px;
        color: #fff;
        font-family: "Noto Serif JP";
        display: block;
        letter-spacing: .1em;
        margin: 0 auto;
        padding: 16px 0;
        position: relative;
        text-align: center;
        width: 412px;
        @include mq() {
            padding: 11px 0;
            width: auto;
        }
        svg {
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            right: 36px;
            top: 0;
            width: 11px;
            .path {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
            }
        }
    }
}

/*---------------------------------
    エディター
---------------------------------*/

.editor {
    margin: 0 0 100px;
    word-break: break-all;
    @include mq() {
        margin: 0 0 50px;
    }
    a {
        text-decoration: underline;
        word-break: inherit;
    }
    figure {
        border-radius: 10px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        max-width: 510px;
        overflow: hidden;
        @include mq() {
            margin-bottom: 15px;
            max-width: none;
        }
    }
    img {
        margin: auto;
        width: auto;
    }
    ol,
    ul {
        list-style: none;
        margin-bottom: 30px;
        @include mq() {
            margin-bottom: 15px;
        }
    }
    ul {
        > li {
            padding: 0 0 0 20px;
            text-indent: 0;
            @include mq() {
                padding: 0 0 0 13px;
            }
            &::before {
                background: #e7233b;
                border-radius: 3px;
                content: "";
                height: 6px;
                left: 0;
                position: absolute;
                top: 15px;
                width: 6px;
                @include mq() {
                    top: 11px;
                }
            }
        }
    }
    ol {
        counter-reset: number;
        > li {
            padding: 0 0 0 1.5em;
            text-indent: -1.5em;
            &::before {
                color: #e7233b;
                counter-increment: number;
                content: counter(number) ".";
                font-family: "Roboto Slab";
                font-weight: bold;
                margin-right: .5em;
            }
        }
    }
    li {
        font-family: "Noto Serif JP";
        font-size: 1.8rem;
        letter-spacing: .1em;
        position: relative;
        @include mq() {
            font-size: 1.6rem;
            line-height: 2.8rem;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    p {
        margin-bottom: 15px;
    }
    table {
        border: 1px solid #e6e6e6;
        width: 100%;
    }
    th,
    td {
        border: 1px solid #e6e6e6;
        padding: 13px;
        text-align: center;
        @include mq() {
            padding: 10px;
        }
    }
    th {
        background: #000;
        color: #fff;
        font-weight: normal;
    }
    td {
        background: #fff;
    }
    &__button {
        background: #000;
        border-radius: 24px;
        color: #fff;
        display: inline-block;
        font-family: "Noto Serif JP";
        font-size: 1.6rem;
        letter-spacing: .1em;
        max-width: 380px;
        overflow: hidden;
        padding: 8px 50px 8px 40px;
        position: relative;
        text-decoration: none !important;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        @include mq() {
            display: block;
            max-width: none;
            text-align: center;
        }
        svg {
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            right: 23px;
            top: 0;
            width: 11px;
            .path {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
            }
        }
    }
    h2,
    h3,
    h4,
    &__h1,
    &__h2,
    &__h3,
    &__h4,
    &__h5 {
        margin-bottom: 15px;
    }
    h2,
    h3,
    h4,
    &__h1,
    &__h2,
    &__h3,
    &__h4 {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
    }
    h2,
    h3,
    h4,
    &__h1,
    &__h2,
    &__h3 {
        padding: 0 0 0 20px;
        position: relative;
        &::before {
            background: #e7233b;
            content: "";
            left: 0;
            position: absolute;
        }
    }
    h2,
    &__h1 {
        font-size: 2.8rem;
        line-height: 5.4rem;
        @include mq() {
            font-size: 2.2rem;
            line-height: 4.2rem;
        }
        &::before {
            border-radius: 2px;
            height: 31px;
            top: 12.5px;
            width: 4px;
            @include mq() {
                height: 27px;
                top: 7.5px;
            }
        }
    }
    h3,
    &__h2 {
        font-size: 2.6rem;
        @include mq() {
            font-size: 2rem;
        }
        &::before {
            border-radius: 3px;
            height: 6px;
            top: 23px;
            width: 6px;
            @include mq() {
                top: 17px;
            }
        }
    }
    h4,
    &__h3 {
        font-size: 2.2rem;
        @include mq() {
            font-size: 1.8rem;
        }
        &::before {
            height: 1px;
            top: 21.5px;
            width: 12px;
            @include mq() {
                top: 17.5px;
            }
        }
    }
    &__h4 {
        font-size: 2rem;
        @include mq() {
            font-size: 1.6rem;
        }
    }
    &__h5 {
        font-weight: bold;
    }
    &__text-align-center {
        text-align: center;
    }
    &__text-align-right {
        text-align: right;
    }
    &__margin-bottom-large {
        margin-bottom: 60px;
        @include mq() {
            margin-bottom: 30px;
        }
    }
    &__horizontal-scroll {
        overflow: auto;
        margin: 0 0 30px;
        @include mq() {
            margin: 0 -60px 15px 0;
        }
        @include mq(mobile) {
            margin: 0 -20px 15px 0;
        }
        > * {
            @include mq() {
                width: 880px !important;
            }
        }
    }
    &__flexbox {
        align-items: flex-start;
        display: flex;
        margin-bottom: 30px;
        margin-left: -30px;
        margin-right: -30px;
        @include mq() {
            display: block;
            margin-bottom: 15px;
            margin-left: 0;
            margin-right: 0;
        }
        > * {
            width: 440px !important;
            margin-bottom: 0 !important;
            margin-left: 30px !important;
            margin-right: 30px !important;
            @include mq() {
                margin-bottom: 15px !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                width: auto !important;
            }
        }
        &_margin {
            &_small {
                margin-bottom: 30px !important;
                margin-left: -19px !important;
                margin-right: -19px !important;
                @include mq() {
                    margin-bottom: 15px !important;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
                > * {
                    width: 288px !important;
                    margin-left: 19px !important;
                    margin-right: 19px !important;
                    @include mq() {
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                        width: auto !important;
                    }
                }
            }
        }
        &_flex-direction {
            &_row-reverse {
                flex-direction: row-reverse;
                @include mq() {
                    flex-direction: column;
                }
            }
        }
        &_card {
            align-items: center;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 0 5px rgba(0, 0, 0, .08);
            justify-content: space-between;
            margin-left: 0;
            margin-right: 0;
            overflow: hidden;
            > * {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
            > div {
                padding: 60px;
                width: 500px !important;
                @include mq() {
                    padding: 20px 30px;
                    width: auto !important;
                }
            }
            > figure {
                border-radius: 0;
                width: 440px !important;
                @include mq() {
                    margin-bottom: 0 !important;
                    width: auto !important;
                }
            }
        }
    }
}
