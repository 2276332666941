/*---------------------------------
    産品を買う
---------------------------------*/

.buy {
    &__slider {
        margin: 0 0 30px;
        padding: 0 105px;
        position: relative;
        visibility: hidden;
        @include mq() {
            margin: 0 0 15px;
            padding: 0;
        }
        &.slick-initialized {
            visibility: visible;
        }
    }
    .slick-slider {
        .slick-arrow {
            -webkit-appearance: none;
            align-items: center;
            background: #000;
            border: none;
            border-radius: 30px;
            bottom: 0;
            display: flex;
            height: 60px;
            justify-content: center;
            margin: auto;
            padding: 0;
            position: absolute;
            top: 0;
            width: 60px;
            z-index: 10;
            @include mq() {
                height: 30px;
                width: 30px;
            }
            &.pc {
                @include mq() {
                    display: none;
                }
            }
            &.sp {
                display: none;
                @include mq() {
                    display: block;
                }
            }
            svg {
                height: 16px;
                width: 11px;
                @include mq() {
                    height: 9px;
                    width: 6px;
                }
                .a {
                    fill: none;
                    stroke: #fff;
                    stroke-miterlimit: 10;
                }
            }
        }
        .slick-prev {
            left: 0;
            @include mq() {
                border-radius: 0 6px 6px 0;
            }
        }
        .slick-next {
            right: 0;
            @include mq() {
                border-radius: 6px 0 0 6px;
            }
        }
    }
    &__text {
        font-size: 1.8rem;
        margin: 0 0 60px;
        @include mq() {
            font-size: 1.4rem;
            margin:  0 0 30px;
        }
    }
    &__title {
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        margin: 0 0 30px;
        padding: 0 0 0 20px;
        position: relative;
        font-size: 2.6rem;
        @include mq() {
            font-size: 2rem;
            margin: 0 0 15px;
        }
        &::before {
            background: #e7233b;
            border-radius: 3px;
            content: "";
            height: 6px;
            left: 0;
            position: absolute;
            top: 23px;
            width: 6px;
            @include mq() {
                top: 17px;
            }
        }
    }
    &__table {
        margin: 0 0 90px;
        width: 100%;
        @include mq() {
            border-bottom: 1px solid #e6e6e6;
            display: block;
            margin: 0 0 30px;
        }
    }
    &__tbody,
    &__tr {
        @include mq() {
            display: block;
        }
    }
    &__th,
    &__td {
        border: 1px solid #e6e6e6;
        font-size: 1.6rem;
        line-height: 3.2rem;
        @include mq() {
            border-color: transparent;
            display: block;
            font-size: 1.4rem;
            line-height: 2.8rem;
        }
    }
    &__th {
        background: #ededed;
        font-weight: bold;
        padding: 16px 22px;
        text-align: left;
        width: 274px;
        @include mq() {
            border-top-color: #e6e6e6;
            padding: 2.5px 1em;
            width: auto;
        }
    }
    &__td {
        overflow: hidden;
        padding: 16px 26px;
        @include mq() {
            min-height: 40px;
            padding: 5px 1em;
        }
    }
    &__text-decoration-underline {
        display: inline-block;
        text-decoration: underline;
    }
    &__font-size-small {
        font-size: 1.4rem;
        font-weight: inherit;
    }
    &__contact {
        background: #fff;
        border-radius: 6px;
        margin: 0 0 60px;
        padding: 50px;
        @include mq() {
            border-radius: 3px;
            margin: 0 0 30px;
            padding: 20px;
        }
    }
    &__name {
        font-size: 2rem;
        font-weight: bold;
        line-height: 3.2rem;
        margin: 0 0 15px;
        text-align: center;
        @include mq() {
            font-size: 1.8rem;
        }
    }
    &__address {
        text-align: center;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -19px 60px;
        @include mq() {
            margin: 0 0 15px;
            justify-content: space-between;
        }
    }
    &__item {
        // margin: 0 19px 30px;
        // width: 288px;
        width: 33.333%;
        
        padding: 0 19px;
        margin-bottom: 30px;
        @include mq() {
            margin: 0 0 15px;
            width: calc(50% - 10px);
            padding:0;
        }
    }
    &__link {
        display: block;
    }
    &__thumbnail {
        border-radius: 6px;
        margin: 0 0 10px;
        @include mq() {
            border-radius: 3px;
        }
    }
    &__supplier {
        text-align: center;
        text-decoration: underline;
        @include mq() {
            font-size: 1.2rem;
            text-align: left;
        }
    }
}
