/*---------------------------------
    共通
---------------------------------*/

html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
}

body {
    background: url(../img/img_8kc1sw.jpg);
    color: #000;
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", "Meiryo", sans-serif;
    font-size: 1.6rem;
    letter-spacing: .05em;
    line-height: 2;
    min-width: 1320px;
    @include mq() {
        font-size: 1.4rem;
        min-width: 320px;
    }
}

html,
body,
body
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
    color:#000;
}

img {
    display: block;
    height: auto;
    width: 100%;
}

@keyframes slidein {
    0% {
        opacity: 0;
    }
    1% {
        transform: translateX(40px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

// @keyframes blink {
//     0% {
//         opacity: .6;
//     }
//     100% {
//         opacity: 1;
//     }
// }

a {
    color: inherit;
    text-decoration: none;
    transition: opacity .3s;
    &:hover {
        // animation: blink 1s cubic-bezier(.22, .61, .36, 1);
        opacity: .6;
    }
}

.main {
    &_active {
        @include mq() {
            margin: 117px 0 0;
        }
    }
}

/*---------------------------------
    ヘッダー
---------------------------------*/

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 70px;
    padding: 45px 50px 30px;
    position: relative;
    z-index: 100;
    flex-wrap: wrap;
    @include mq() {
        background: rgba(0, 0, 0, 0);
        height: 117px;
        justify-content: center;
        margin: 0;
        padding: 0;
    }
    &_active {
        @include mq() {
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
    &__form{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        &_dv{
            &_pc{
                @include mq() {
                    display: none;
                }
            }
            &_sp{
                display: none;
                @include mq() {
                    display: flex;
                    margin-top: 15px;
                    justify-content: center;
                    width: 300px;
                    margin: 20px auto 0;
                }
                .header__inp{
                    width: 100%;
                }
            }
        }
    }
    &__inp{
        border:none;
        width: 240px;
        padding: 8px 12px;
        border-radius: 5px;
        @include mq() {
            padding: 10px 14px;
        }
    }
    &__bt{
        position: absolute;
        right: 5px;
        top: calc(50% - 8px);
        background:none;
        border:none;
        svg{
            width: 16px;
            height: 16px;
        }
    }
}

.logo {
    flex: none;
    height: 84px;
    margin: 0 20px 0 0;
    width: 210px;
    @include mq() {
        height: 57px;
        margin: 0;
        width: 152px;
    }
    &__link {
        height: inherit;
        display: block;
        width: inherit;
        &:hover {
            // animation: none;
            opacity: 1;
        }
    }
    svg {
        height: inherit;
        width: inherit;
        .a {
            fill: #937e28;
        }
        .b {
            fill: #c9ba88;
        }
        .c {
            fill: #000;
            transition: .3s;
        }
        .d {
            fill: #e50012;
        }
    }
    &_active {
        svg {
            .c {
                @include mq() {
                    fill: #fff;
                }
            }
        }
    }
}

.hamburger {
    cursor: pointer;
    display: none;
    height: 16px;
    position: fixed;
    right: 30px;
    top: 50px;
    width: 37px;
    @include mq() {
        display: block;
    }
    &__border {
        background: #000;
        box-shadow: 0 0 1px #fff;
        height: 2px;
        transition: .3s;
        position: absolute;
        right: 0;
        width: inherit;
        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2) {
            top: 7px;
        }
        &:nth-child(3) {
            bottom: 0;
        }
        &_active {
            background: #fff;
            box-shadow: none;
            &:nth-child(1) {
                transform: translateY(7px) rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
                transform: translateX(16px);
            }
            &:nth-child(3) {
                transform: translateY(-6px) rotate(-45deg);
            }
        }
    }
}

.gnav {
    align-items: center;
    display: flex;
    @include mq() {
        display: block;
        height: calc(100vh - 117px);
        left: 0;
        opacity: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 20px 0 30px;
        position: fixed;
        top: 117px;
        visibility: hidden;
        width: 100%;
    }
    &_active {
        @include mq() {
            opacity: 1;
            transition: opacity .3s;
            visibility: visible;
        }
    }
    &__list {
        display: flex;
        @include mq() {
            display: block;
            margin: 0 0 40px;
            padding: 0 20px;
        }
    }
    &__item {
        margin: 0 10px;
        @include mq() {
            margin: 0 0 13px;
        }
    }
    &__link {
        display: block;
        font-family: "Noto Serif JP";
        // letter-spacing: .1em;
        @include mq() {
            color: #fff;
            font-size: 1.6rem;
            text-align: center;
        }
        &_white-space {
            &_nowrap {
                white-space: nowrap;
            }
        }
    }
    &__child-list {
        display: none;
        @media screen and (min-width: 769px) {
            display: none !important;
        }
    }
    &__child-item {
        @include mq() {
            margin: 10px 0 0;
        }
    }
    &__child-link {
        @include mq() {
            color: #fff;
            display: block;
            font-family: "Noto Serif JP";
            font-size: 1.5rem;
            text-align: center;
        }
    }
}

.app {
    flex: none;
    margin: 0 0 0 20px;
    position: relative;
    @include mq() {
        margin: 0;
        padding: 0 20px;
    }
    &__button {
        background: #e7233b;
        border-radius: 32px;
        color: #fff;
        display: block;
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        padding: 10px 0;
        position: relative;
        text-align: center;
        width: 158px;
        z-index: 10;
        @include mq() {
            display: none;
        }
    }
    &__list {
        background: #fff;
        border: 3px solid #000;
        border-radius: 10px;
        left: 50%;
        margin: auto;
        opacity: 0;
        position: absolute;
        top: 85px;
        transform: translateX(-50%) translateY(-10px);
        transition: opacity .4s, transform .4s, visibility 0s .4s;
        visibility: hidden;
        width: 248px;
        @include mq() {
            background: none;
            border: none;
            border-radius: 0;
            left: auto;
            max-width: 300px;
            opacity: 1;
            position: relative;
            top: auto;
            transform: translateX(0);
            transition: none;
            visibility: inherit;
            width: auto;
        }
        &::before,
        &::after {
            content: "";
            height: 24px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: -24px;
            width: auto;
            @include mq() {
                content: none;
            }
        }
        &::before {
            background: #000;
            width: 3px;
        }
    }
    &:hover {
        .app__list {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
            transition: opacity .4s, transform .4s;
            visibility: inherit;
            @include mq() {
                transform: translateX(0);
                transition: none;
            }
        }
    }
    &__item {
        &:not(:last-child) {
            border-bottom: 1px solid #e6e6e6;
            @include mq() {
                border-bottom: none;
                margin: 0 0 20px;
            }
        }
    }
    &__link {
        display: block;
        font-size: 1.4rem;
        overflow: hidden;
        padding: 11px 11px 11px 35px;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include mq() {
            background: #e7233b;
            border-radius: 25px;
            color: #fff;
            margin: auto;
            padding: 10px;
            text-align: center;
        }
        svg {
            bottom: 0;
            height: 6px;
            left: 17px;
            margin: auto;
            position: absolute;
            top: 0;
            width: 11px;
            @include mq() {
                display: none;
            }
            .path {
                fill: none;
                stroke: #e7233b;
                stroke-miterlimit: 10;
            }
        }
    }
}

.overlay {
    display: none;
    @include mq() {
        background: #000;
        display: block;
        height: 100vh;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        visibility: hidden;
        width: 100%;
        z-index: -10;
    }
    &_active {
        @include mq() {
            opacity: 1;
            transition: opacity .3s;
            visibility: visible;
        }
    }
}

/*---------------------------------
    フッター
---------------------------------*/

.footer {
    &__container {
        background: #fff;
        padding: 70px;
        @include mq() {
            padding: 40px 20px;
        }
    }
    &__content {
        align-items: center;
        display: flex;
        margin: 0 0 50px;
        @include mq() {
            flex-direction: column;
            justify-content: center;
            margin: 0 0 20px;
        }
    }
    &__sp {
        display: none;
        @include mq() {
            display: block;
        }
    }
    &__logo {
        flex: none;
        height: 84px;
        width: 224px;
        @include mq() {
            height: 57px;
            margin: 0 0 20px;
            width: 152px;
        }
    }
    &__img {
        height: inherit;
        width: inherit;
    }
    &__list {
        margin: 0 50px;
        @include mq() {
            margin: 0 0 20px;
        }
    }
    &__term {
        font-family: "Noto Serif JP";
        font-size: 1.8rem;
        letter-spacing: .1em;
        @include mq() {
            font-size: 1.6rem;
            margin: 0 0 10px;
            text-align: center;
        }
    }
    &__description {
        @include mq() {
            text-align: center;
        }
    }
    &__sns-list {
        display: flex;
        margin: 0 0 0 auto;
        @include mq() {
            margin: auto;
        }
    }
    &__sns-item {
        height: 36px;
        width: 36px;
        &:not(:last-child) {
            margin: 0 30px 0 0;
        }
    }
    &__sns-link {
        height: inherit;
        display: block;
        width: inherit;
    }
    &__copyright {
        display: block;
        font-family: "Roboto Slab";
        font-size: 1.2rem;
        letter-spacing: .1em;
        @include mq() {
            text-align: center;
        }
    }
}

.nav {
    background: #000;
    padding: 65px 0;
    @include mq() {
        padding: 55px 0 50px;
    }
    &__container {
        @include container;
        display: flex;
        justify-content: space-around;
        @include mq() {
            display: block;
        }
    }
    &__list {
        padding: 50px;
        @include mq() {
            padding: 0;
        }
        &:not(:first-child) {
            border-left: 1px solid #333;
            @include mq() {
                border-left: none;
            }
        }
    }
    &__item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin: 0;
        &:not(:last-child) {
            margin: 0 0 10px;
            @include mq() {
                margin: 0;
            }
        }
    }
    &__link {
        color: #fff;
        font-family: "Noto Serif JP";
        letter-spacing: .1em;
        padding: 0 0 0 17px;
        position: relative;
        @include mq() {
            font-size: 1.6rem;
        }
        svg {
            bottom: 0;
            height: 6px;
            margin: auto;
            position: absolute;
            left: 0;
            top: 0;
            width: 11px;
            .path {
                fill: none;
                stroke: #e7233b;
                stroke-miterlimit: 10;
            }
        }
    }
    &__child-list {
        @include mq() {
            margin: 0 0 5px;
        }
    }
    &__child-item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
    &__child-link {
        color: #fff;
        display: block;
        font-family: "Noto Serif JP";
        font-size: 1.5rem;
        margin: 0 0 0 20px;
        padding: 0 0 0 17px;
        position: relative;
        @include mq() {
            line-height: 2.5rem;
        }
        &::before {
            background: #e7233b;
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            top: 14.5px;
            width: 10px;
            @include mq() {
                top: 13px;
            }
        }
    }
}

.kabosuBn{
    width: 200px;
    height: 200px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index:10;
    @include mq() {
        width: 140px;
        height: 140px;
        right: 20px;
        bottom: 20px;
    }
    &.close{
        display: none;
    }
    &__link{
        position: relative;
        background-color: #fff;
        border-radius: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    &__img{
        width: calc(100% - 20px);
        width: calc(100% - 20px);
        object-fit: cover;
        border-radius: 200px;
    }
    &__close{
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        border-radius: 20px;
        background-color: #eee;
        position: absolute;
        z-index: 2;
        cursor: pointer;
        top: 5%;
        right: 7.5%;
        @include mq() {
            right: 5%;
        }
        &:before{
            width: 52%;
            height: 2px;
            content: "";
            background: #000;
            display: block;
            transform: rotate(-45deg);
            top: 1px;
            position: relative;
        }
        &:after{
            width: 52%;
            height: 2px;
            content: "";
            background: #000;
            display: block;
            transform: rotate(45deg);
            top: -1px;
            position: relative;
        }
    }
    &__text{
        position: absolute;
        bottom: -.5em;
        left: -10%;
        display: block;
        background-color: #e7233b;
        color: #fff;
        padding: 10px 15px;
        border-radius: 100px;
        line-height: 1.35;
        text-align: center;
        font-size: 1.4rem;
        width: 120%;
        letter-spacing: -.025em;

        @include mq() {
            font-size: 1.0rem;
            font-weight: bold;
            padding: 7px 10px;
            width: 125%;
            left: -12.5%;
        }
    }
}